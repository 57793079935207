import React from 'react';
import Img from 'gatsby-image';
import { graphql, Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import PrevNext from '../components/prevnext';
import MetaTags from '../components/metatags';
import HeaderExpanded from '../components/HeaderExpanded/HeaderExpanded';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function BlogPost(props) {
  const url = props.data.site.siteMetadata.siteUrl;
  const thumbnail =
    props.data.markdownRemark.frontmatter.image &&
    props.data.markdownRemark.frontmatter.image.childImageSharp.resize.src;
  const { title, image, tags, caption } = props.data.markdownRemark.frontmatter;
  const { prev, next } = props.pageContext;

  return (
    <>
      <MetaTags
        title={title}
        description={props.data.markdownRemark.excerpt}
        thumbnail={thumbnail && url + thumbnail}
        url={url}
        pathname={props.location.pathname}
      />
      <HeaderExpanded title='News' image='' />
      <Container className='my-3'>
        <div className='my-3'>
          <Link to='/news'>
            <FontAwesomeIcon icon={faArrowLeft} /> Back to News
          </Link>
        </div>
        {image && (
          <Row>
            <Col>
              <Img fluid={image.childImageSharp.fluid} />
              <small className='d-block text-right'>{caption}</small>
            </Col>
          </Row>
        )}
        <h1 className='hp-title hp-title--subtitle-twothirds my-4'>{title}</h1>
        <div className='mb-3'>
          <div
            dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}
          />
          <hr></hr>
          {/* <div>
            <hr></hr>
            <div className="bg-light p-4">
              <span>Tagged in: </span>
              {tags.map((tag, i) => (
                <a href={`/${tag}`} key={i} style={{ marginLeft: '10px' }}>
                  {tag}
                </a>
              ))}
            </div>
          </div> */}
        </div>
        <PrevNext prev={prev && prev.node} next={next && next.node} />
      </Container>
    </>
  );
}

export default BlogPost;

export const query = graphql`
  query PostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        tags
        image {
          childImageSharp {
            resize(width: 1000, height: 420) {
              src
            }
            fluid(maxWidth: 786) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        caption
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
