import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const PrevNext = (props) => {
  const { prev, next } = props;

  return (
    <>
      <Container className='mb-3 px-0'>
        <Row>
          {prev && (
            <Col md={6}>
              <a
                href={prev.fields.slug}
                className='d-block bg-primary text-white text-decoration-none py-3 px-3 mb-2 text-left'
              >
                <FontAwesomeIcon icon={faArrowLeft} /> {prev.frontmatter.title}
              </a>
            </Col>
          )}
          {next && (
            <Col
              md={6}
              className=''
            >
              <a
                href={next.fields.slug}
                className='d-block bg-primary text-white text-decoration-none py-3 px-3 mb-2 text-right'
              >
                {next.frontmatter.title} <FontAwesomeIcon icon={faArrowRight} />{' '}
              </a>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default PrevNext;
